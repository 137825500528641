import 'lazysizes';
import 'lazysizes/plugins/optimumx/ls.optimumx';
import { $$ } from '../helpers/query-selector.js';
import { saveData } from '../helpers/variables.js';

// Load lower resolution images if saveData
if (saveData) {
  const images = $$('.lazyload');
  for (const image of images) image.dataset.optimumx = 1;
}

// Remove bg to avoid artefacts
document.addEventListener('lazyloaded', e => {
  const parent = e.target.parentNode;

  if (parent.classList.contains('o-image__wrapper')) {
    parent.classList.add('o-image__wrapper--transparent');
  }
});
