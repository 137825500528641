import { $, $$ } from '../helpers/query-selector.js';
import { reducedMotion, saveData } from '../helpers/variables.js';

function playVideo(video) {
  if (
    video.paused &&
    !video.hasAttribute('controls') &&
    !reducedMotion.matches &&
    !saveData
  ) {
    if (video.readyState === 0 || video.readyState === 1) {
      video.addEventListener('loadeddata', () => {
        video.play();
      });
    } else {
      video.play();
    }

    // Show controls if play not possible
    video.controlsTimeout = setTimeout(() => {
      if (video.paused) {
        video.setAttribute('controls', 'true');
      }
    }, 3000);
  }

  if (!video.hasAttribute('controls') && (reducedMotion.matches || saveData)) {
    video.setAttribute('controls', 'true');
  }
}

function pauseVideo(video) {
  if (!video.paused) video.pause();

  clearTimeout(video.controlsTimeout);
}

function fadeInVideo(video) {
  video.classList.remove('video-lazyload');
  video.classList.add('video-lazyloaded');
  video.parentNode.classList.add('o-video__wrapper--transparent');
}

const loadObserver = new IntersectionObserver(
  (entries, observer) => {
    for (const entry of entries) {
      const video = entry.target;

      if (entry.isIntersecting && video.classList.contains('video-lazyload')) {
        const sources = $$('source', video);

        for (const source of sources) {
          source.setAttribute('src', source.dataset.src);
        }

        video.load();

        video.addEventListener('loadeddata', () => {
          fadeInVideo(video);
        });

        // Fallback
        setTimeout(() => {
          fadeInVideo(video);
        }, 2000);

        loadObserver.unobserve(video);
      }
    }
  },
  { rootMargin: '0% 0% 100% 0%' }
);

const playObserver = new IntersectionObserver(
  (entries, observer) => {
    for (const entry of entries) {
      const video = entry.target;

      if (entry.isIntersecting) {
        playVideo(video);
      } else {
        pauseVideo(video);
      }
    }
  },
  { rootMargin: '-30% 0%' }
);

function handleUnmute(video) {
  const unmuteButton = $('.js-video-unmute', video.parentNode);
  if (!unmuteButton) return;

  video.addEventListener('loadeddata', () => {
    unmuteButton.classList.remove('o-video__unmute--hidden');

    unmuteButton.addEventListener('click', () => {
      unmuteButton.classList.add('o-video__unmute--hidden');
      video.removeAttribute('muted');
      video.setAttribute('controls', 'true');
      video.muted = false;
      video.volume = 1.0;
    });
  });
}

function init() {
  const videos = $$('.js-video video');

  for (const video of videos) {
    handleUnmute(video);

    loadObserver.observe(video);
    playObserver.observe(video);
  }
}

export default init;
