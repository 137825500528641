__InitRollup__; // Replaced by Rollup

import './modules/lazyload.js';
import './modules/set-vh.js';
import initNav from './modules/nav.js';
import initVideo from './modules/video.js';
import { $ } from './helpers/query-selector.js';

import('focus-visible');

async function init() {
  const polyfills = [];

  if (!('IntersectionObserver' in window)) {
    polyfills.push(import('intersection-observer'));
  }

  await Promise.all(polyfills);

  initNav();
  initVideo();

  if ($('.js-lottie')) import('./modules/lottie.js');
  if ($('.js-slideshow')) import('./modules/slideshow.js');
  if ($('.js-intro')) import('./modules/intro.js');
  if ($('.js-text-image-columns')) import('./modules/text-image-columns.js');
  if ($('.js-freestyle')) import('./modules/freestyle.js');
  if ($('.js-countdown')) import('./modules/countdown.js');
  if ($('.js-scroll-text')) import('./modules/scroll-text.js');
  if ($('script[data-consent-required]')) import('./modules/cookie-consent.js');
}

init();
